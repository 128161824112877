import React from 'react';
import Article from '../../Article';

function SobreEmpresa(){
    return(
        <div>
        <Article />
        </div>
    );
};

export default SobreEmpresa;