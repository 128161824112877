import React from 'react';
import Inicio from '../../Inicio'

function Main(){
    return(
        
        <div>
        <Inicio />
        </div>
    );
};

export default Main;