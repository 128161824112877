import React from 'react';
import { Jumbotron, Container } from 'reactstrap';
import Cardex from './components/Cardex';
import Cardex2 from './components/Cardex2';
import Cardex3 from './components/Cardex3';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#FFFFFF',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
}));

const Article = (props) => {
  return (
    <div>
      <Jumbotron fluid>
      <Container fluidmaxWidth="sm">
          <h1 className="display-3">Cloud</h1>
          <p className="lead">
                    
          </p>
          
          <Grid container spacing={1}>
  <Grid item xs={6} md={4}>
    <Item><Cardex />
    </Item>
  </Grid>
  <Grid item xs={6} md={4}>
    <Item><Cardex2 />
    </Item>
  </Grid>
  <Grid item xs={6} md={4}>
    <Item>
    <Cardex3 />
      </Item>
  </Grid>
 </Grid>


        </Container>
      </Jumbotron>
    </div>
  );
};

export default Article;