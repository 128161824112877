import React from 'react';
import Routes from './routes';
import Header2 from './Header2';
import Footer from './Footer';



function App () {
  return (
    <div>
      
        <Header2 />
        <Routes />
        <Footer />

    </div>    
  );
};

export default App;
