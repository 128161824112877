import React from 'react';
import Contato from '../../Contato';

function App(){
    return(
        <div>
        <Contato />
        </div>
    );
};

export default Contato;