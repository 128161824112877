import React from 'react';
import Hosting from '../../Hosting'

function Main(){
    return(
        
        <div>
        <Hosting />
        </div>
    );
};

export default Main;