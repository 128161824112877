import React from 'react';
import Cloud from "../../Cloud";


function App(){
    return(
        <div>
        <Cloud />
        </div>
    );
};

export default Cloud;