import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Folder from "@mui/icons-material/Folder";
import { Container, Row, Col } from 'react-grid-system';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#6959CD',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
}));


export default function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);

  return (
    <Container>
    <Box sx={{ width: 500 }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Recents" icon={<RestoreIcon />} />
        <BottomNavigationAction label="Favorite" icon={<FavoriteIcon />} />
        <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
        <BottomNavigationAction label="Folder" icon={<Folder />} />
      </BottomNavigation>
    </Box>
    
    <Grid container spacing={1}>
  <Grid item xs={6} md={4}>
    <Item>FocoDireto Cloud Computing
    <br />
    <br />
    <br />
    </Item>
  </Grid>
  <Grid item xs={6} md={4}>
    <Item>Contato <br/> 11 99898-4780
    <br />
    <br />
    </Item>
  </Grid>
  <Grid item xs={6} md={4}>
    <Item>Soluções <br />
          Temos soluções para você fornecer suporte online e vendas de produtos digitais tais como ebook, programs e outros.</Item>
  </Grid>
 </Grid>
    
      
     
    </Container>
  );
}
