import React from 'react';
import Privacidade from '../../Privacidade'

function Main(){
    return(
        
        <div>
        <Privacidade />
        </div>
    );
};

export default Privacidade;