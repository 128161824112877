import React from 'react';
import { Link } from 'react-router-dom';
import { Jumbotron, Container } from 'reactstrap';


const Article = (props) => {
  return (
    <div>
      <Jumbotron fluid>
      <Container fluidmaxWidth="sm">
          <h2 className="display-3">Cloud Computing: o que é e para que serve?</h2>
          <p className="lead">
          De uma forma simples, cloud computing, ou computação na nuvem, é uma tecnologia que permite acesso remoto a softwares, armazenamento de arquivos e processamento de dados por meio da internet. É uma alternativa para você acessar dados importantes de qualquer computador, em qualquer lugar.

O cloud computing é para grandes negócios e também para empresas de pequeno e médio porte. Este mecanismo oferece aos indivíduos e às empresas a capacidade de um pool de recursos digitais com boa manutenção, seguro, de fácil acesso e sob demanda como servidores, armazenamento de dados e software de aplicativo.

O cloud computing proporciona às empresas mais flexibilidade em relação a dados e informações, que podem ser acessados a qualquer hora. É ideal para empresas com sedes ao redor do mundo ou em diferentes ambientes de trabalho, abrangendo locais remotos. Com o mínimo de gerenciamento, todos os elementos de software da computação na nuvem podem ser dimensionados sob demanda: você precisa apenas de uma conexão à internet.
          </p>
          <a class="btn btn-primary btn-lg" href="/cloud" role="button">Saiba mais</a>
        </Container>
      </Jumbotron>
    </div>
  );
};

export default Article;